import { STERILIZATION_AUDITS_PROCESSHISTORY } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Process History", "search-by": "Description", "ph-search-by": "Search by Description" } }, [_c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description", "sorter": true } }), _c("icon-field", { key: "UpdateNextAuditDate", attrs: { "title": "Update Next Audit Date", "align": "center", "data-index": "updateNextAuditDate", "render-icon": _vm.renderIcon, "width": 164, "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListProcessHistory",
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListProcessHistory = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-process-history" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "sterilization.process-histories", "api-url": _vm.apiUrl, "create-route": "/sterilization-audits/process-history/create", "edit-route": "/sterilization-audits/process-history/:id", "list": _vm.ListProcessHistory, "page": _vm.page } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ProcessHistory",
  data() {
    return {
      page: STERILIZATION_AUDITS_PROCESSHISTORY,
      ListProcessHistory,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "processhistory",
          title: "Process History",
          path: "/sterilization-audits/process-history"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
